<template>
  <div class="new_css">
      <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="转正提醒" name="转正提醒">
              <div class="calc-height">
                  <el-table :data="users"
                            highlight-current-row
                            height="100%"
                            @current-change="selectCurrentRow"
                            class="new_table">
                      <el-table-column prop="UserInfoUserName" label="名称">
                      </el-table-column>
                      <el-table-column label="部门" prop="Dpt"></el-table-column>
                      <el-table-column prop="Post" label="岗位"> </el-table-column>
                      <el-table-column label="截止日期"
                                       prop="EndDate"
                                       :formatter="formatStartTime">
                          <template slot-scope="scope">
                              <span style="color: red">
                                  转正时间截止：{{
                    dateFormat(scope.row.EndDate, "yyyy-MM-dd")
                                  }}
                              </span>
                          </template>
                      </el-table-column>
                  </el-table>
              </div>
              <!-- 分页器 -->
              <el-pagination align="center"
                             @current-change="handleCurrentChange"
                             :current-page="currentPage"
                             :page-size="pageSize"
                             layout="prev, pager, next, jumper,total"
                             :page-count="total"
                             :total="totaldata">
              </el-pagination>
          </el-tab-pane>
          <el-tab-pane label="超龄提醒" name="超龄提醒">
              <div class="calc-height">
                  <el-table :data="age"
                            highlight-current-row
                            height="100%"
                            @current-change="selectCurrentRow1"
                            class="new_table">
                      <el-table-column prop="Factory" label="公司"> </el-table-column>
                      <el-table-column label="部门" prop="Dpt"></el-table-column>
                      <el-table-column prop="Post" label="岗位"> </el-table-column>
                      <el-table-column prop="UserName" label="姓名"> </el-table-column>
                      <el-table-column prop="Age" label="年龄"> </el-table-column>
                      <el-table-column prop="Male" label="性别"> </el-table-column>
                      <el-table-column prop="IDCard"
                                       show-overflow-tooltip
                                       label="身份证号">
                      </el-table-column>

                      <el-table-column label="入职时间"
                                       prop="Hiredate"
                                       :formatter="formatStartTime"></el-table-column>
                      <el-table-column label="延期聘用结束时间"
                                       prop="RetireEnd"
                                       :formatter="formatStartTime"></el-table-column>
                      <el-table-column label="安置结果"
                                       prop="RetireResult"></el-table-column>
                      <el-table-column label="延期聘用开始时间"
                                       prop="RetireStart"
                                       :formatter="formatStartTime"></el-table-column>
                      <el-table-column label="到期时间"
                                       prop="expDate"
                                       :formatter="formatStartTime"></el-table-column>
                  </el-table>
              </div>
              <!-- 分页器 -->
              <el-pagination align="center"
                             @current-change="handleCurrentChange1"
                             :current-page="currentPage1"
                             :page-size="pageSize1"
                             layout="prev, pager, next, jumper,total"
                             :page-count="total1"
                             :total="totaldata1">
              </el-pagination>
          </el-tab-pane>
          <el-tab-pane label="证书提醒" name="证书提醒">
              <div class="calc-height">
                  <el-table :data="Certificate"
                            highlight-current-row
                            height="100%"
                            @current-change="selectCurrentRow2"
                            class="new_table">
                      <el-table-column prop="UserName" label="姓名"> </el-table-column>
                      <el-table-column prop="UserState" label="用户状态">
                      </el-table-column>
                      <el-table-column prop="CertName" label="证书名称">
                      </el-table-column>
                      <el-table-column prop="CertEndDate"
                                       label="有效期限"
                                       :formatter="formatStartTime">
                      </el-table-column>
                      <el-table-column prop="diffDate" label="距离复审日期时长">
                          <template slot-scope="scope">
                              <span style="color: red">
                                  距离复审日期还有：{{ scope.row.diffDate }}天
                              </span>
                          </template>
                      </el-table-column>
                      <el-table-column label="操作" width="100">
                          <template slot-scope="scope">
                              <el-button @click="PositiveDetail(scope.row)"
                                         type="text"
                                         size="small">编辑</el-button>
                          </template>
                      </el-table-column>
                  </el-table>
              </div>
              <!-- 分页器 -->
              <el-pagination align="center"
                             @current-change="handleCurrentChange2"
                             :current-page="currentPage2"
                             :page-size="pageSize2"
                             layout="prev, pager, next, jumper,total"
                             :page-count="total2"
                             :total="totaldata2">
              </el-pagination>
          </el-tab-pane>
          <el-tab-pane label="证书有效期提醒" name="证书有效期提醒">
              <div class="calc-height">
                  <el-table :data="CertificateValidity"
                            highlight-current-row
                            height="100%"
                            @current-change="selectCurrentRowVal"
                            class="new_table">
                      <el-table-column prop="UserName" label="姓名"> </el-table-column>
                      <el-table-column prop="UserState" label="用户状态">
                      </el-table-column>
                      <el-table-column prop="CertName" label="证书名称">
                      </el-table-column>
                      <el-table-column prop="ValidityDate"
                                       label="有效期限"
                                       :formatter="formatStartTime">
                      </el-table-column>
                      <el-table-column prop="diffDate" label="距离失效日期时长">
                          <template slot-scope="scope">
                              <span style="color: red">
                                  距离失效日期还有：{{ scope.row.diffDate }}天
                              </span>
                          </template>
                      </el-table-column>
                      <el-table-column label="操作" width="100">
                          <template slot-scope="scope">
                              <el-button @click="PositiveDetail(scope.row)"
                                         type="text"
                                         size="small">编辑</el-button>
                          </template>
                      </el-table-column>
                  </el-table>
              </div>
              <!-- 分页器 -->
              <el-pagination align="center"
                             @current-change="handleCurrentChangeVal"
                             :current-page="currentPageVal"
                             :page-size="pageSizeVal"
                             layout="prev, pager, next, jumper,total"
                             :page-count="totalVal"
                             :total="totaldataVal">
              </el-pagination>
          </el-tab-pane>
          <el-tab-pane label="证书补贴提醒" name="证书补贴提醒">
              <div class="calc-height">
                  <el-table :data="CertificateRemind"
                            highlight-current-row
                            height="100%"
                            @current-change="selectCurrentRow3"
                            class="new_table">
                      <el-table-column prop="UserName" label="姓名"> </el-table-column>
                      <el-table-column prop="UserState" label="用户状态">
                      </el-table-column>
                      <el-table-column prop="CertName" label="证书名称">
                      </el-table-column>
                      <el-table-column prop="SubsidyDate"
                                       label="补贴日期"
                                       :formatter="formatStartTime">
                      </el-table-column>
                      <el-table-column prop="diffDate" label="距离补贴日期时长">
                          <template slot-scope="scope">
                              <span style="color: red">
                                  距离补贴日期还有：{{ scope.row.diffDate }}天
                              </span>
                          </template>
                      </el-table-column>
                  </el-table>
              </div>
              <!-- 分页器 -->
              <el-pagination align="center"
                             @current-change="handleCurrentChange3"
                             :current-page="currentPage3"
                             :page-size="pageSize3"
                             layout="prev, pager, next, jumper,total"
                             :page-count="total3"
                             :total="totaldata3">
              </el-pagination>
          </el-tab-pane>
          <el-tab-pane label="合同提醒" name="合同提醒">
              <div class="calc-height">
                  <el-table :data="Contract"
                            highlight-current-row
                            height="100%"
                            @current-change="selectCurrentRow4"
                            class="new_table">
                      <el-table-column prop="UserName" label="姓名"> </el-table-column>
                      <el-table-column prop="Male" label="性别"> </el-table-column>
                      <el-table-column prop="Dpt" label="部门"> </el-table-column>
                      <el-table-column prop="Post" label="职务"> </el-table-column>
                      <el-table-column prop="ConTypeName" label="合同类型">
                      </el-table-column>

                      <el-table-column prop="StartDate"
                                       :formatter="formatStartTime"
                                       label="开始日期">
                      </el-table-column>
                      <el-table-column prop="EndDate"
                                       :formatter="formatStartTime"
                                       label="结束日期">
                      </el-table-column>
                      <el-table-column prop="DiffDate" label="距离合同日期时长">
                          <template slot-scope="scope">
                              <span style="color: red">
                                  合同时长：{{ scope.row.DiffDate }}天
                              </span>
                          </template>
                      </el-table-column>
                  </el-table>
              </div>
              <!-- 分页器 -->
              <el-pagination align="center"
                             @current-change="handleCurrentChange4"
                             :current-page="currentPage4"
                             :page-size="pageSize4"
                             layout="prev, pager, next, jumper,total"
                             :page-count="total4"
                             :total="totaldata4">
              </el-pagination>
          </el-tab-pane>
      </el-tabs>
    <el-dialog
      title="编辑"
      :visible.sync="SakaryVisible"
      v-model="SakaryVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :rules="SakaryRules"
        ref="SakaryForm"
        :model="SakaryForm"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名:" prop="UserName">
              <el-input
                type="text"
                :disabled="true"
                v-model="SakaryForm.UserName"
              >
                <!-- <el-button slot="append" icon="el-icon-search" @click="chooseUser"></el-button> -->
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证号:" prop="IDCard">
              <el-input
                type="text"
                v-model="SakaryForm.IDCard"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="证书名称:" prop="CertName">
              <el-input type="text" v-model="SakaryForm.CertName"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证书系列:" prop="CertType">
              <el-select
                v-model="SakaryForm.CertType"
                filterable
                placeholder="证书系列"
                @change="CertTypeModel($event)"
                style="width: 100%"
              >
                <el-option
                  v-for="item in CertTypeLists"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="类别:" prop="CertCategory">
              <el-select
                v-model="SakaryForm.CertCategory"
                filterable
                placeholder="证书系列"
                @change="CertCategoryModel($event)"
                style="width: 100%"
              >
                <el-option
                  v-for="item in CertCategoryLists"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="专业:" prop="CertProfession">
              <el-select
                v-model="SakaryForm.CertProfession"
                filterable
                placeholder="证书系列"
                @change="CertProfessionModel($event)"
                style="width: 100%"
              >
                <el-option
                  v-for="item in CertProfessionLists"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证书编号:" prop="CertCode">
              <el-input type="text" v-model="SakaryForm.CertCode"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="注册编号:" prop="RegisterCode">
              <el-input type="text" v-model="SakaryForm.RegisterCode">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="管理号:" prop="ManageCode">
              <el-input type="text" v-model="SakaryForm.ManageCode"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发证单位:" prop="CertAssUnit">
              <el-input type="text" v-model="SakaryForm.CertAssUnit">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="评定单位:" prop="CertFairUnit">
              <el-input type="text" v-model="SakaryForm.CertFairUnit">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="签发时间:" prop="CertFairDate">
              <el-date-picker
                type="date"
                v-model="SakaryForm.CertFairDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="变更日期:" prop="ChangeDate">
              <el-date-picker
                type="date"
                v-model="SakaryForm.ChangeDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="有效期限:" prop="CertEndDate">
              <el-date-picker
                type="date"
                v-model="SakaryForm.CertEndDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="存档日期:" prop="CertSaveDate">
              <el-date-picker
                type="date"
                v-model="SakaryForm.CertSaveDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="补贴日期:" prop="SubsidyDate">
              <el-date-picker
                type="date"
                v-model="SakaryForm.SubsidyDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="补贴金额:" prop="SubsidyMoney">
              <el-input type="money" v-model="SakaryForm.SubsidyMoney">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="复审周期:" prop="CertCycle">
              <el-select
                v-model="SakaryForm.CertCycle"
                filterable
                placeholder="复审周期"
                style="width: 100%"
              >
                <el-option label="0" value="0"></el-option>
                <el-option label="1" value="1"></el-option>
                <el-option label="2" value="2"></el-option>
                <el-option label="3" value="3"></el-option>
                <el-option label="4" value="4"></el-option>
                <el-option label="5" value="5"></el-option>
                <el-option label="6" value="6"></el-option>
                <el-option label="7" value="7"></el-option>
                <el-option label="8" value="8"></el-option>
                <el-option label="9" value="9"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发放周期:" prop="CerFFTime">
                <el-select v-model="SakaryForm.CerFFTime"
                           placeholder="发放周期"
                           style="width: 100%">
                    <el-option label="无" value="无"></el-option>
                    <el-option label="半年度" value="半年度"></el-option>
                    <el-option label="季度" value="季度"></el-option>
                    <el-option label="月度" value="月度"></el-option>
                </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <el-form-item label="发放方式:" prop="CerFFType">
                    <el-select v-model="SakaryForm.CerFFType"
                               placeholder="发放方式"
                               style="width: 100%">
                        <el-option label="无" value="无"></el-option>
                        <el-option label="集团发放" value="集团发放"></el-option>
                        <el-option label="工资发放" value="工资发放"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="发放公司:" prop="PaymentAccount">
                    <el-select v-model="SakaryForm.PaymentAccount"
                               placeholder="发放公司"
                               style="width: 100%">
                        <el-option label="无" value="无"></el-option>
                        <el-option label="格瑞德集团" value="格瑞德集团"></el-option>
                        <el-option label="人工环境设计研究院" value="人工环境设计研究院"></el-option>
                        <el-option label="冷却设备有限公司" value="冷却设备有限公司"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <el-form-item label="获取方式:" prop="CertGetType">
                    <el-select v-model="SakaryForm.CertGetType"
                               placeholder="获取方式"
                               style="width: 100%">
                        <el-option label="个人" value="个人"></el-option>
                        <el-option label="公司" value="公司"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="证书状态:" prop="CertState">
                    <el-select v-model="SakaryForm.CertState"
                               filterable
                               placeholder="证书状态"
                               style="width: 100%">
                        <el-option label="在职未过期" value="在职未过期"></el-option>
                        <el-option label="在职已过期" value="在职已过期"></el-option>
                        <el-option label="离职未过期" value="离职未过期"></el-option>
                        <el-option label="离职已过期" value="离职已过期"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            
        </el-row>
        <el-row>
            <el-col :span="12">
                <el-form-item label="存放地点:" prop="SavePlace">
                    <el-select v-model="SakaryForm.SavePlace"
                               filterable
                               placeholder="存放地点"
                               @change="SavePlaceModel($event)"
                               style="width: 100%">
                        <el-option v-for="item in SavePlaceLists"
                                   :key="item.Id"
                                   :label="item.Name"
                                   :value="item.Name"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="使用有效期:" prop="ValidityDate">
                    <el-date-picker type="date"
                                    v-model="SakaryForm.ValidityDate"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期"></el-date-picker>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item label="存放状态变更说明(借阅记录):"
                              prop="CertSaveState"
                              label-width="200px">
                    <el-select v-model="SakaryForm.CertSaveState" placeholder="选择">
                        <el-option label="已借用" value="已借用"></el-option>
                        <el-option label="未借用" value="未借用"></el-option>
                        <el-option label="在职取走" value="在职取走"></el-option>
                        <el-option label="离职带走" value="离职带走"></el-option>
                        <el-option label="离职未带走" value="离职未带走"></el-option>
                        <el-option label="换证复审" value="换证复审"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <label for="Summarize" class="el-form-item__label">备注:</label>

            <el-input
              type="textarea"
              v-model="SakaryForm.Remark"
              style="width: 90%"
            ></el-input>
          </el-col>
        </el-row>
        <upload-files
          :files="SakaryForm.FileArry"
          :key="key"
          action="/cyl/ftp/ftp/upload"
          :limit="20"
          @fun="dealFiles"
          :IsDisabled="operation1"
          :IsDel="operation1"
        ></upload-files>
        <el-row v-if="operation1">
          <el-col :span="24" style="text-align: center">
          
              <el-button type="primary" v-on:click="onSubmit('SakaryForm')"
                >提交</el-button
              >
         
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";

import {
  GetProbationRemindPageList,
        GetCertificateRemindPageList,
        GetCertificateValidityPageList,
  GetSalaryRemindPageList,
  GetContractRemindPageList,
  GetAgeRemindPageList,
  GetCertificateByType,
  GetCertificateDetail,
  UpdateCertificate,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
import UploadFiles from "../../components/UploadFiles";
export default {
  components: { UploadFiles },
  data() {
    return {
      activeName: "转正提醒",
      users: [],
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      age: [],
      currentPage1: 1, // 当前页码
      total1: null, // 总条数
      totaldata1: null,
      pageSize1: 20, // 每页的数据条数
      page1: 1,
      operation1: true,
      Certificate: [],
      currentPage2: 1, // 当前页码
      total2: null, // 总条数
      totaldata2: null,
      pageSize2: 200, // 每页的数据条数
      page2: 1,
      CertificateRemind: [],
      currentPage3: 1, // 当前页码
      total3: null, // 总条数
      totaldata3: null,
      pageSize3: 20, // 每页的数据条数
        page3: 1,
        CertificateValidity: [],
        currentPageVal: 1, // 当前页码
        totalVal: null, // 总条数
        totaldataVal: null,
        pageSizeVal: 20, // 每页的数据条数

      Contract: [],
      currentPage4: 1, // 当前页码
      total4: null, // 总条数
      totaldata4: null,
      pageSize4: 20, // 每页的数据条数
      page4: 1,
      key: 1,
      SakaryVisible: false,
      CertTypeLists: [],
      CertCategoryLists: [],
      CertProfessionLists: [],
      SavePlaceLists: [],
      SakaryForm: {
        UserName: "",
        UserId: "",
        CertTypeId: "",
        CertName: "",
        CertCategoryId: "",
        CertType: "",
        CertCategory: "",
        CertProfessionId: "",
        CertProfession: "",
        CertCode: "",
        ManageCode: "",
        RegisterCode: "",
        CertAssUnit: "",
        CertFairUnit: "",
        ChangeDate: "",
        CertFairDate: "",
        CertEndDate: "",
        CertSaveDate: "",
        SubsidyMoney: "",
        SubsidyDate: "",
        CertCycle: "0",
        CerFFTime: "季度",
        CerFFType: "集团发放",
        CertGetType: "个人",
        CertState: "在职未过期",
        SavePlaceId: "",
        SavePlace: "",
        CertSaveState: "",
        Remark: "",
        FileArry: [],
      },
      SakaryRules: {
        UserName: [{ required: true, message: "请输入名称", trigger: "blur" }],
        CertType: [
          { required: true, message: "请选择证书系列", trigger: "blur" },
        ],
        CertCategory: [
          { required: true, message: "请选择类别", trigger: "blur" },
        ],
        CertProfession: [
          { required: true, message: "请选择专业", trigger: "blur" },
        ],
        CertCode: [
          { required: true, message: "请输入证书编号", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    PositiveDetail(row) {
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      console.log(row);
      GetCertificateByType({ type: "证书系列" }).then((res) => {
        console.log(res);
        this.CertTypeLists = res.data.response;
      });
      GetCertificateByType({ type: "证书类别" }).then((res) => {
        this.CertCategoryLists = res.data.response;
      });
      GetCertificateByType({ type: "证书专业" }).then((res) => {
        this.CertProfessionLists = res.data.response;
      });
      GetCertificateByType({ type: "存放地点" }).then((res) => {
        this.SavePlaceLists = res.data.response;
      });
      GetCertificateDetail({ id: row.Id }).then((res) => {
        console.log(res);
        this.SakaryForm = res.data.response;
        this.key += 1;
        this.SakaryVisible = true;
      });
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    dateFormat: function (time, style) {
      var date = time;
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), style);
    },
    handleClick(tab, event) {
      switch (tab.name) {
        case "转正提醒":
          this.getData();
          break;
        case "超龄提醒":
          this.getAgeRemindPageList();
          break;
        case "证书提醒":
          this.getCertificateRemindPageList();
              break;
          case "证书有效期提醒":
              this.getCertificateValidityPageList();
              break;
        case "证书补贴提醒":
          this.getSalaryRemindPageList();
          break;
        case "合同提醒":
          this.getContractRemindPageList();
          break;
      }
    },
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData();
    },
    selectCurrentRow1(val) {
      this.currentRow1 = val;
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.page1 = val;
      this.getAgeRemindPageList();
    },
    selectCurrentRow2(val) {
      this.currentRow2 = val;
      },
      selectCurrentRowVal(val) {
          this.currentRowVal = val;
      },
      // 证书有效期提醒 该表
      handleCurrentChangeVal(val) {
          this.currentPageVal = val;
          this.pageVal = val;
          this.getCertificateValidityPageList();
      },
    handleCurrentChange2(val) {
      this.currentPage2 = val;
      this.page2 = val;
      this.getCertificateRemindPageList();
    },
    selectCurrentRow3(val) {
      this.currentRow3 = val;
    },
    handleCurrentChange3(val) {
      this.currentPage3 = val;
      this.page3 = val;
      this.getSalaryRemindPageList();
    },
    selectCurrentRow4(val) {
      this.currentRow4 = val;
    },
    handleCurrentChange4(val) {
      this.currentPage4 = val;
      this.page4 = val;
      this.getContractRemindPageList();
    },
    //获取列表
    getData() {
      let para = {
        page: this.page,
        size: this.pageSize,
      };
      console.log(para);
      GetProbationRemindPageList(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
      });
    },
    getAgeRemindPageList() {
      let para = {
        page: this.page1,
        size: this.pageSize1,
      };
      console.log(para);
      GetAgeRemindPageList(para).then((res) => {
        console.log(res);
        this.total1 = res.data.response.pageCount;
        this.totaldata1 = res.data.response.dataCount;
        this.age = res.data.response.data;
      });
    },
    getCertificateRemindPageList() {
      let para = {
        page: this.page2,
        size: this.pageSize2,
      };
      console.log(para);
      GetCertificateRemindPageList(para).then((res) => {
        console.log(res);
        this.total2 = res.data.response.pageCount;
        this.totaldata2 = res.data.response.dataCount;
        this.Certificate = res.data.response.data;
      });
      },
     // 证书有效期提醒
      getCertificateValidityPageList() {
          let para = {
              page: this.pageVal,
              size: this.pageSizeVal,
          };
          console.log(para);
          GetCertificateValidityPageList(para).then((res) => {
              console.log(res);
              this.totalVal = res.data.response.pageCount;
              this.totaldataVal = res.data.response.dataCount;
              this.CertificateValidity = res.data.response.data;
          });
      },
    getSalaryRemindPageList() {
      let para = {
        page: this.page3,
        size: this.pageSize3,
      };
      console.log(para);
      GetSalaryRemindPageList(para).then((res) => {
        console.log(res);
        this.total3 = res.data.response.pageCount;
        this.totaldata3 = res.data.response.dataCount;
        this.CertificateRemind = res.data.response.data;
      });
    },
    getContractRemindPageList() {
      let para = {
        page: this.page4,
        size: this.pageSize4,
      };
      console.log(para);
      GetContractRemindPageList(para).then((res) => {
        console.log(res);
        this.total4 = res.data.response.pageCount;
        this.totaldata4 = res.data.response.dataCount;
        this.Contract = res.data.response.data;
      });
    },
    dealFiles(data) {
      this.SakaryForm.FileArry = data.backData;
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let para = Object.assign({}, this.SakaryForm);
            console.log(para);

            UpdateCertificate(para).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                  duration: 5000,
                });
                this.SakaryVisible = false;
                this.getData();
              } else {
                this.$message({
                  message: "修改失败",
                  type: "error",
                  duration: 5000,
                });
              }
            });
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>